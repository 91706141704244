

var Constants = require("../Core/Constants");
var MarkdownToHtmlConverter = null;

if (!__IS_OLD_WEBPACK__)
{
    
    MarkdownToHtmlConverter = require("markdown-it")({
        html: false,
        linkify: false,
        breaks: true,
        typographer: true
    }).use(require("markdown-it-ins"));
}

var EstsError = Constants.EstsError;
var c_AttributeCollectionPrefix = "Attribute_";
var c_OptionItemPrefix = "_Option_";
var c_HeaderSuffix = ".Header";
var c_BodySuffix = ".Body";


var StringCustomizationHelper =
{
    
    resolveConditionalAccessPageErrorName: function (errorCode)
    {
        switch (errorCode)
        {
            case EstsError.ApplicationUsedIsNotAnApprovedAppRequiredByConditionalAccess: return "ApplicationUsedIsNotAnApprovedAppRequiredByConditionalAccess";
            case EstsError.BlockedByConditionalAccess: return "BlockedByConditionalAccess";
            case EstsError.BlockedByConditionalAccessForRemoteDeviceFlow: return "BlockedByConditionalAccessForRemoteDeviceFlow";
            case EstsError.BrokerAppNotInstalled: return "BrokerAppNotInstalled";
            case EstsError.BrokerAppNotInstalledDeviceAuthenticationFailed: return "BrokerAppNotInstalledDeviceAuthenticationFailed";
            case EstsError.DeviceIsNotWorkplaceJoined: return "DeviceIsNotWorkplaceJoined";
            case EstsError.DeviceIsNotWorkplaceJoinedForMamApp: return "DeviceIsNotWorkplaceJoinedForMamApp";
            case EstsError.DeviceNotCompliant: return "DeviceNotCompliant";
            case EstsError.DeviceNotCompliantBrowserNotSupported: return "DeviceNotCompliantBrowserNotSupported";
            case EstsError.DeviceNotCompliantDeviceCompliantRequired: return "DeviceNotCompliantDeviceCompliantRequired";
            case EstsError.DeviceNotCompliantDeviceManagementRequired: return "DeviceNotCompliantDeviceManagementRequired";
            case EstsError.DeviceNotDomainJoined: return "DeviceNotDomainJoined";
            case EstsError.DeviceNotDomainJoinedBrowserNotSupported: return "DeviceNotDomainJoinedBrowserNotSupported";
            case EstsError.ProofUpBlockedDueToRisk: return "ProofUpBlockedDueToRisk";
            case EstsError.ProofUpBlockedDueToUserRisk: return "ProofUpBlockedDueToUserRisk";
            case EstsError.RemediateCompliantApp: return "RemediateCompliantApp";
            case EstsError.RemediateDeviceStateManagedBrowserRequired: return "RemediateDeviceStateManagedBrowserRequired";
            case EstsError.RemediateDeviceStateWorkplaceJoinRequired: return "RemediateDeviceStateWorkplaceJoinRequired";
            default: return "";
        }
    },

    
    resolveProofUpRedirectPageErrorName: function (errorCode)
    {
        switch (errorCode)
        {
            case EstsError.AuthenticatorAppRegistrationRequiredInterrupt: return "AuthenticatorAppRegistrationRequiredInterrupt";
            case EstsError.AuthenticatorAppRegistrationEnforcementInterrupt: return "AuthenticatorAppRegistrationEnforcementInterrupt";
            case EstsError.UserStrongAuthEnrollmentRequiredInterrupt: return "UserStrongAuthEnrollmentRequiredInterrupt";
            case EstsError.UserStrongAuthClientAuthNRequiredInterrupt: return "UserStrongAuthClientAuthNRequiredInterrupt";
            case EstsError.AdminConsentRequired: return "AdminConsentRequired";
            case EstsError.AdminConsentRequiredRequestAccess: return "AdminConsentRequiredRequestAccess";
            default: return "";
        }
    },

    
    resolveErrorPageErrorName: function (errorCode)
    {
        switch (errorCode)
        {
            case EstsError.RequiredDeviceStateNotSupported: return "RequiredDeviceStateNotSupported";
            case EstsError.AdminConsentRequired: return "AdminConsentRequired";
            case EstsError.AdminConsentRequiredRequestAccess: return "AdminConsentRequiredRequestAccess";
            default: return "";
        }
    },

    
    parseMarkdownString: function (stringToConvert)
    {
        if (MarkdownToHtmlConverter)
        {
            return MarkdownToHtmlConverter.render(stringToConvert);
        }

        return stringToConvert;
    },

    
    customStringResolver: function (stringsArray, stringId)
    {
        for (var i = 0; i < stringsArray.length; i++)
        {
            if (stringsArray[i].key.toLowerCase() === stringId.toLowerCase())
            {
                return stringsArray[i].value;
            }
        }

        return "";
    },

    
    getAttributeCollectionString: function (stringsArray, attributeId)
    {
        if (!stringsArray || !attributeId)
        {
            return;
        }

        var stringId = c_AttributeCollectionPrefix + attributeId;
        var customString = this.customStringResolver(stringsArray, stringId);

        return customString;
    },

    
    getAttributeCollectionOptionString: function (stringsArray, attributeId, optionId)
    {
        if (!stringsArray || !attributeId || !optionId)
        {
            return;
        }

        var stringId = c_AttributeCollectionPrefix + attributeId + c_OptionItemPrefix + optionId;
        var customString = this.customStringResolver(stringsArray, stringId);

        return customString;
    },

    
    parseAttributeCollectionOptionString: function (stringsArray, attributeId, optionId)
    {
        if (!optionId)
        {
            return;
        }

        var customString = this.getAttributeCollectionOptionString(stringsArray, attributeId, optionId.replace(/\s+/g, "")) || optionId;

        
        
        var htmlOutput = this.parseMarkdownString(customString)
            .replace(/<p>/g, "<span>")
            .replace(/<\/p>/g, "</span>")
            .replace(/<a href="/g, "<a target=\"_blank\" href=\"");

        return htmlOutput;
    },

    
    parseCustomHeader: function (stringsArray, errorName)
    {
        var stringId = errorName + c_HeaderSuffix;
        var customHeader = this.customStringResolver(stringsArray, stringId);

        return customHeader;
    },

    
    parseCustomBody: function (stringsArray, errorName)
    {
        var stringId = errorName + c_BodySuffix;
        var customBody = this.customStringResolver(stringsArray, stringId);

        return this.parseMarkdownString(customBody);
    }

};

module.exports = StringCustomizationHelper;